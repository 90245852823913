<template>
  <b-container class="secretarias" v-loading="loading">
    <div class="secretarias-content" v-if="Object.keys(secretaria).length">
      <Topo :secretaria="secretaria" />
      <Lista :secretaria="secretaria" />
      <Mapa :coord="secretaria.coordenada" />
      <Tabs :secretaria="secretaria" />
      <div v-if="secretaria.fluxograma">
        <img class="fluxo" :src="secretaria.fluxograma" />
      </div>
      <!-- <Links :items="['Notícias', 'Contratos', 'Editais']" /> -->
    </div>
  </b-container>
</template>

<script>
import Topo from "../components/Secretarias/Topo.vue";
import Lista from "@/components/Secretarias/Lista.vue";
import Mapa from "../components/Secretarias/Mapa.vue";
import Tabs from "../components/Secretarias/Tabs.vue";
//import Links from "@/components/Secretarias/Links.vue";
export default {
  components: {
    Topo,
    Lista,
    Mapa,
    Tabs,
    //Links,
  },
  name: "secretarias",
  data() {
    return {
      loading: false,
      secretaria: {},
    };
  },
  watch: {
    "$route.params.id"() {
      this.getSecretarias();
    },
  },
  methods: {
    async getSecretarias() {
      this.loading = true;
      this.secretaria = {};
      const id = this.$route.params.id;
      const res = await this.$axios.get("secretaria").catch((e) => {
        console.error("Secretaria", e);
      });
      if (res.status === 200) {
        this.secretaria = res.data.filter((sec) => sec.id.toString() === id)[0];
      }
      console.log(this.secretaria);
      this.loading = false;
    },
  },
  created() {
    this.getSecretarias();
  },
};
</script>

<style lang="scss">
.secretarias {
  padding: 0;
  min-height: 300px;
}

.fluxo {
  width: 100%;
}
</style>
